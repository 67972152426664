.alert {
  position: fixed;
  display: flex;
  justify-content: space-around;
  align-items: center;
  right: 10px;
  top: 10px;
  width: 260px;
  height: 50px;
  padding: 10px;
  border-radius: 3px;
  z-index: 9999;

  background: var(--alert-background-color);

  .button--regular {
    padding: 5px 20px;
  }
}
