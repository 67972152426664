@import '../variables.scss';

.rotation-message {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--rotation-message-background-color);
  color: var(--rotation-message-color);
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  p {
    max-width: 250px;
    line-height: 22px;
    color: var(--rotation-message-color);
  }

  svg {
    fill: var(--rotation-message-color);
    height: 60px;
    width: 60px;
    margin-bottom: $gap5;
  }
}

.APP.MOBILEDEVICE.MOB-PORT-SHOW {
  @media (min-width: $small) and (max-width: $xmedium) and (orientation: landscape) {
    .rotation-message {
      display: flex;
    }
  }
}
