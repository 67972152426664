@import '../variables.scss';

.reg-int {
  position: fixed;
  top: 70px;
  left: 0;
  height: 36px;
  width: 100vw;
  z-index: 2;
  pointer-events: none;

  button {
    color: var(--register-interest-button-color);
    background-color: var(--register-interest-button-background-color);
    border: none;
    padding: 0 10px;
    height: 36px;
    line-height: 36px;
    font-size: 100%;
    cursor: pointer;
    transition: 300ms;
    pointer-events: all;

    @include rounded(false, false, true, true);
    @include roundedElectron(true, true, false, false);

    @media (hover: hover) {
      &:hover {
        background-color: var(--register-interest-button-hover-background-color);
        color: var(--register-interest-button-hover-color);
      }
    }
  }

  @at-root .APP.ELECTRON & {
    top: auto;
    bottom: 70px;
  }

  @media (max-width: $medium) {
    &.reg-int-hide-mob {
      display: none;
    }
  }

  @media (min-width: $small) and (max-width: $xmedium) and (orientation: landscape) {
    & button {
      display: none !important;
    }
  }
}
