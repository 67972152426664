@import './variables.scss';

.APP {
  width: 100vw;
  height: 100%;
  overflow: hidden;
  text-align: center;
  display: flex;
  flex-direction: column;

  &.WINDOWS * {
    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  &.ELECTRON {
    flex-direction: column-reverse;
  }

  &.MOBILEDEVICE.MOB-PORT-SHOW {
    @media (min-width: $small) and (max-width: $xmedium) and (orientation: landscape) {
      & > * {
        display: none;
      }
    }
  }
}

.asset-caching-notice {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    position: absolute;
    right: 10px;
  }
}
