.embed-gallery { 
  position: relative; 
  height: 100%;
  width: 100%;
  overflow: hidden; 

  iframe, 
  object, 
  embed { 
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%; 
    border: none;
  
  }

}