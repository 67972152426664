/* 
  DEFAULT THEME
  Overrides to come from CMS Feed
**/

:root {
  --theme-primary: rgba(68, 68, 68, 1);
  --theme-primary-tint: rgba(68, 68, 68, 0.7);
  --theme-accent: rgba(136, 136, 136, 1);

  --black: rgba(0, 0, 0, 1);
  --offblack: rgba(51, 51, 51, 1);
  --grey: rgba(170, 170, 170, 1);
  --offwhite: rgba(238, 238, 238, 1);
  --white: rgba(255, 255, 255, 1);

  /* GLOBAL */
  /* ------ */

  --h1-fontsize: 40px;
  --h2-fontsize: 34px;
  --h3-fontsize: 32px;
  --h4-fontsize: 18px;
  --h5-fontsize: 16px;

  --rotation-message-background-color: var(--black);
  --rotation-message-color: var(--white);

  --paragraph-fontsize: 15px;
  --paragraph-color: var(--offblack);

  --link-color: var(--theme-primary);
  --link-hover-color: var(--theme-accent);

  --button-background-color: rgba(255, 255, 255, 0);
  --button-border-color: var(--theme-primary);
  --button-color: var(--theme-primary);

  --button-active-background-color: var(--theme-accent);
  --button-active-border-color: var(--theme-accent);
  --button-active-color: var(--white);

  --favourite-icon-fill-color: var(--theme-primary);
  --favourite-icon-active-fill-color: var(--theme-accent);

  --enlarge-zoom-button-background-color: var(--theme-primary-tint);
  --enlarge-zoom-button-active-background-color: var(--theme-primary);
  --enlarge-zoom-button-color: var(--white);

  --consent-background-color: var(--white);
  --consent-manage-button-color: var(--white);
  --consent-manage-button-background-color: orange;

  --consent-accept-button-color: var(--white);
  --consent-accept-button-background-color: green;

  --consent-essential-button-color: var(--white);
  --consent-essential-button-background-color: orange;

  --consent-decline-button-color: var(--white);
  --consent-decline-button-background-color: red;

  /* COMPONENTS */
  /* ---------- */

  // Header
  --header-background-color: var(--theme-primary);
  --header-icon-color: var(--white);
  --header-icon-active-color: var(--theme-accent);

  --header-icon-divider-color: var(--offwhite);

  --register-interest-button-color: var(--white);
  --register-interest-button-background-color: var(--theme-accent);

  --register-interest-button-hover-color: var(--white);
  --register-interest-button-hover-background-color: var(--theme-primary);

  // Drop Panel
  --drop-panel-background-color: var(--white);
  --drop-panel-link-color: var(--black);
  --drop-panel-link-hover-color: var(--theme-primary);

  --confirm-drop-panel-background-color: var(--theme-primary);
  --confirm-drop-panel-color: var(--white);

  // Sidebar
  --sidebar-background-color: var(--black);
  --sidebar-background-color-tint: rgba(0, 0, 0, 0);
  --sidebar-color: var(--white);
  --sidebar-title-color: var(--white);
  --sidebar-divider-color: rgba(255, 255, 255, 0.5);
  --sidebar-icon-color: var(--white);

  --sidebar-inputfield-background-color: var(--white);
  --sidebar-list-background-color: var(--white);

  --sidebar-button-background-color: var(--button-background-color);
  --sidebar-button-border-color: var(--button-border-color);
  --sidebar-button-color: var(--button-color);

  --sidebar-button-active-background-color: var(--button-active-background-color);
  --sidebar-button-active-border-color: var(--button-active-border-color);
  --sidebar-button-active-color: var(--button-active-color);

  // Property Bar
  --property-bar-title-color: var(--black);

  // Grid
  --reactive-grid-gap: 3px;

  // Alert
  --alert-background-color: var(--white);

  // Forms
  --input-background-color: var(--offwhite);

  --select-input-arrow-color: var(--grey);

  --checkbox-color: var(--grey);
  --checkbox-active-color: var(--theme-primary);
  --checkbox-label-link-color: var(--link-color);

  --form-label-color: var(--black);

  --form-submit-button-color: var(--white);
  --form-submit-button-background-color: var(--theme-primary);
  --form-field-validation-color: rgba(255, 102, 68, 1);

  /* SECTIONS */
  /* -------- */

  // Splash Screen
  --splash-background-color: var(--black);
  --splash-font-color: var(--white);
  --splash-font-shadow: var(--black);
  --splash-trigger-color: var(--white);

  // Magazine
  --magazine-link-color: var(--link-color);

  --magazine-h1-color: var(--black);
  --magazine-h1-fontsize: 36px;

  --magazine-h2-color: var(--black);
  --magazine-h2-fontsize: 28px;

  --magazine-h3-color: var(--black);
  --magazine-h3-fontsize: 18px;

  --magazine-h4-color: var(--black);
  --magazine-h4-fontsize: 16px;

  --magazine-h5-color: var(--black);
  --magazine-h5-fontsize: 14px;

  --magazine-h6-color: var(--black);
  --magazine-h6-fontsize: 12px;

  --magazine-paragraph-color: var(--paragraph-color);
  --magazine-paragraph-fontsize: 100%;

  --magazine-li-color: var(--black);
  --magazine-li-fontsize: 100%;

  // Gallery
  --image-gallery-background-color: var(--black);

  --image-gallery-rotate-icon-color: var(--white);

  --image-gallery-gradient-color-top: rgba(0, 0, 0, 0);
  --image-gallery-gradient-color-bottom: rgba(0, 0, 0, 0.7);

  --image-gallery-pagination-arrow-color: var(--white);
  --image-gallery-pagination-color: var(--white);

  --image-gallery-disclaimer-color: var(--offwhite);
  --image-gallery-disclaimer-fontsize: 70%;

  --video-gallery-placeholder-background-color: var(--offblack);

  // Specs
  --specifications-button-background-color: var(--white);
  --specifications-button-border-color: var(--white);
  --specifications-button-color: var(--offblack);

  --specifications-button-active-background-color: var(--button-active-background-color);
  --specifications-button-active-border-color: var(--button-active-border-color);
  --specifications-button-active-color: var(--button-active-color);

  --specifications-button-disabled-background-color: rgba(255, 255, 255, 0.5);
  --specifications-button-disabled-border-color: var(--specifications-button-border-color);
  --specifications-button-disabled-color: var(--specifications-button-color);

  --specifications-heading-background-color: var(--offblack);
  --specifications-heading-color: var(--white);

  --specifications-image-overlay-color: rgba(255, 255, 255, 0.5);
  --specifications-image-overlay-border-thickness: 0px;
  --specifications-image-overlay-border-color: var(--white);

  --specifications-image-border-thickness: 1px;
  --specifications-image-border-color: var(--white);

  // Location
  --location-info-background-color: var(--black);
  --location-info-color: var(--white);

  --location-info-button-background-color: var(--offblack);
  --location-info-button-color: var(--white);

  --location-info-button-active-background-color: var(--button-active-background-color);
  --location-info-button-active-color: var(--button-active-color);

  --location-info-icon-color: var(--grey);
  --location-info-icon-active-color: var(--button-color);

  --location-accordion-button-background-color: var(--theme-primary);
  --location-accordion-button-border-color: var(--theme-primary);
  --location-accordion-button-color: var(--white);

  --location-accordion-button-active-background-color: var(--theme-accent);
  --location-accordion-button-active-border-color: var(--theme-accent);
  --location-accordion-button-active-color: var(--white);

  --location-accordion-button-divider-thickness: 1px;
  --location-accordion-button-divider-color: rgba(255, 255, 255, 0.5);

  --location-accordion-button-child-category-active-background-color: var(--theme-accent);
  --location-accordion-button-child-category-active-border-color: var(--theme-accent);
  --location-accordion-button-child-category-active-color: var(--offblack);

  --location-accordion-list-background-color: var(--white);
  --location-accordion-list-color: var(--offblack);
  --location-accordion-list-active-color: var(--theme-accent);
  --location-accordion-list-divider-color: var(--grey);

  --location-accordion-searchinput-background-color: var(--white);

  --location-bearing-icon-color: var(--theme-accent);

  --location-loading-background-color: var(--theme-primary-tint);
  --location-loading-color: var(--theme-accent);

  // Transport
  --transport-bus-line-color: var(--white);

  --transport-bakerloo-line-color: var(--white);
  --transport-central-line-color: var(--white);
  --transport-circle-line-color: var(--white);
  --transport-district-line-color: var(--white);
  --transport-elizabeth-line-color: var(--white);
  --transport-hammersmith_city-line-color: var(--white);
  --transport-jubilee-line-color: var(--white);
  --transport-metropolitan-line-color: var(--white);
  --transport-northern-line-color: var(--white);
  --transport-piccadilly-line-color: var(--white);
  --transport-victoria-line-color: var(--white);
  --transport-waterloo_city-line-color: var(--white);
  --transport-dlr-line-color: var(--white);
  --transport-london_overground-line-color: var(--white);
  --transport-london_trams-line-color: var(--white);
  --transport-emirates_cable_car-line-color: var(--white);

  // Model
  --model-intro-skipbutton-background-color: var(--white);
  --model-intro-skipbutton-border-color: var(--white);
  --model-intro-skipbutton-color: var(--offblack);

  --model-background-color: var(--white);

  --model-favourite-icon-fill-color: var(--favourite-icon-fill-color);
  --model-favourite-icon-active-fill-color: var(--favourite-icon-active-fill-color);

  --model-marker-favourite-icon-active-fill-color: var(--model-favourite-icon-active-fill-color);

  --model-aside-title-color: var(--white);
  --model-aside-background-color: var(--white);

  --model-aside-button-wrapper-background-color: var(--white);

  --model-aside-button-background-color: var(--button-background-color);
  --model-aside-button-border-color: var(--button-border-color);
  --model-aside-button-color: var(--button-color);

  --model-aside-button-active-background-color: var(--button-active-background-color);
  --model-aside-button-active-border-color: var(--button-active-border-color);
  --model-aside-button-active-color: var(--button-active-color);

  --model-aside-compare-background-color: var(--white);
  --model-aside-floorstacker-active-background-color: rgba(0, 0, 0, 0.1);
  --model-aside-compareslot-heading-background-color: var(--offwhite);
  --model-aside-compareslot-border-color: var(--offblack);

  --model-compare-border-color: var(--offblack);
  --model-compare-section-background-color: var(--white);

  // Schedule
  --schedule-background-color: var(--white);
  --schedule-divider-color: var(--grey);
  --schedule-item-hover-background-color: var(--offwhite);
  --schedule-item-color: var(--offblack);
  --schedule-item-title-color: var(--theme-primary);
  --schedule-item-icon-color: var(--black);
  --schedule-item-price-color: var(--black);
  --schedule-item-price-fontsize: 25px;

  --schedule-favourite-icon-fill-color: var(--favourite-icon-fill-color);
  --schedule-favourite-icon-active-fill-color: var(--favourite-icon-active-fill-color);

  --schedule-floorplan-pagination-color: var(--theme-accent);
  --schedule-floorplan-pagination-arrow-color: var(--theme-accent);

  --schedule-compare-border-color: var(--grey);

  --schedule-no-results-color: var(--offblack);

  // Detail (and compare detail)
  --detail-background-color: var(--white);

  --detail-favourite-icon-fill-color: var(--favourite-icon-fill-color);
  --detail-favourite-icon-active-fill-color: var(--favourite-icon-active-fill-color);

  --detail-divider-color: var(--offblack);

  --detail-button-background-color: var(--button-background-color);
  --detail-button-border-color: var(--button-border-color);
  --detail-button-color: var(--button-color);

  --detail-button-active-background-color: var(--button-active-background-color);
  --detail-button-active-border-color: var(--button-active-border-color);
  --detail-button-active-color: var(--button-active-color);

  --detail-dimms-h2-color: var(--black);
  --detail-dimms-h4-color: var(--black);
  --detail-dimms-paragraph-color: var(--black);

  --detail-dimms-control-gradient-top-color: rgba(255, 255, 255, 0);
  --detail-dimms-control-gradient-bottom-color: rgba(255, 255, 255, 1);

  --detail-floorplan-pagination-color: var(--theme-accent);
  --detail-floorplan-pagination-arrow-color: var(--theme-accent);

  --detail-bar-background-color: var(--grey);
  --detail-bar-title-fontsize: var(--h1-fontsize);
  --detail-bar-title-color: var(--white);
  --detail-bar-color: var(--offwhite);

  --detail-more-button-color: var(--offblack);
  --detail-more-button-active-color: var(--link-color);

  // Floorplans
  --floorplan-zoom-background-color: var(--white);

  // Siteplan
  --siteplan-image-height: 600px;
  --siteplan-status-marker-border-thickness: 0px;
  --siteplan-status-marker-border-color: var(--white);

  // Contact
  --contact-background-color: var(--white);
  --contact-paragraph-color: var(--paragraph-color);
  --contact-link-color: var(--link-color);
  --contact-link-hover-color: var(--link-hover-color);
  --contact-social-link-color: var(--link-color);
  --contact-social-link-hover-color: var(--link-hover-color);
}
