@import "../variables.scss";

.icon-button {
  font-weight: 700;
  border: none;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  background: none;
  color: var(--white);
  
  &--slim {
    font-size: 1.4em;
    line-height: 14px;
    padding: 6px 14px;
  
  }
  
  &--regular {
    font-size: 1.6em;
    line-height: 16px;
    padding: 10px 12px;
  
  }
  
  &--large {
    font-size: 1.6em;
    line-height: 16px;
    padding: 12px 14px;
    
  }

  &:disabled {
    opacity: 0.3;
  }

  @media(min-width: 480px){
  
    &--slim {
      font-size: 1.4em;
      padding: 5px 24px;
  
    }
    
    &--regular {
      font-size: 1.6em;
      padding: 10px 20px;
  
    }
    
    &--large {
      font-size: 1.6em;
      padding: 12px 24px;
  
    }
  
  }

}


