@import './variables.scss';

.scene {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;

  &.fourofour {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &.large-view,
  &.spec-gallery {
    background-color: #000000;
    overflow: initial;

    h2 {
      color: white;
    }
  }

  &.large-view .tour-embed iframe {
    width: 100vw;
    height: 100%;
    border: none;
  }

  &.schedule {
    overflow: hidden;
    overflow-y: hidden;

    .scrollable {
      padding-bottom: 70px;
    }

    @at-root .APP.ELECTRON & {
      .scrollable {
        padding-bottom: 0px;
      }
      &:before {
        bottom: 135px;
      }
    }
  }
}
