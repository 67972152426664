@import '../variables.scss';

.tour-embed {
  position: relative;
  height: 100%;
  width: 100%;

  .tour-zoom-button {
    position: absolute;
    right: 20px;
    bottom: 20px;
    width: 40px;
    height: 40px;
    background-color: var(--enlarge-zoom-button-background-color);
    padding: 10px;
    @include rounded(true, true, true, true);
    svg {
      fill: var(--enlarge-zoom-button-color);
      width: 20px;
      height: 20px;
    }

    &:hover {
      background-color: var(--enlarge-zoom-button-active-background-color);
    }
  }
}
