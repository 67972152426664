.drop-panel {
  position: fixed;
  width: 100vw;
  height: 100%;
  background-color: var(--drop-panel-background-color);
  color: var(--drop-panel-link-color);
  z-index: 4;
  left: 0;
  top: -100%;
  padding: 100px 10px 30px 10px;
  overflow: hidden;
  overflow-y: scroll;

  button {
    background: none;
    border: none;
    font-size: 30px;
    color: var(--drop-panel-link-color);
    text-transform: uppercase;
    text-decoration: none;
  }

  .pixel-logo {
    height: 50px;
    align-self: flex-end;
    margin-top: 4vh;
    margin-right: 25px;
  }

  &#navigation {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    button {
      transition: color 200ms;
      cursor: pointer;

      &:hover {
        color: var(--drop-panel-link-hover-color);
      }
    }

    .small-nav-links {
      display: flex;
      gap: 10px;
      button {
        font-size: 100%;
      }

      span {
        width: 1px;
        background-color: var(--drop-panel-link-color);
        height: 20px;
      }
    }
  }

  &#confirm {
    background-color: var(--confirm-drop-panel-background-color);
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--confirm-drop-panel-color);

    p {
      color: var(--confirm-drop-panel-color);
    }

    button {
      color: var(--confirm-drop-panel-color);
    }
  }

  @at-root .APP.ELECTRON & {
    top: auto;
    bottom: -100%;
    padding: 30px 10px 130px 10px;
  }
}
