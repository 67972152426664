@import "../variables.scss";
@import "../mixins.scss";

.reactive-grid {
  position: relative;
  display: grid;
  gap: var(--reactive-grid-gap);
  height: 100%;

  button {
    position: relative;
    margin: 0;
    padding: 0;
    color: var(--white);
    font-weight: bold;
    font-size: 28px;
    border:none;
    text-transform: uppercase;
    text-shadow: 0px 0px 25px var(--black);
    cursor: pointer;

    div {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      top: 0;
      
    }
  
  }

  div span { 
    white-space: break-spaces;
    padding: 0 20px;
  
  }
  
  img {  
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    left: 0;
    top: 0;
    transition: 300ms;
  
  }
  
  video {  
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    left: 0;
    top: 0;
    transition: 300ms;
  
  }

  @media(hover: hover){
  
    button:hover img {
      filter: opacity(0.8);
    
    }
  
  }

  span.rg-subtitle {
    font-size: 20px;      
    text-transform: initial;

  }

  /* Variations */
  
  @media(orientation: landscape){
  
    &[data-tiles="1"]{
      grid-template-columns: 1fr;
      grid-template-rows: 100%;
    
    }
  
    &[data-tiles="2"]{
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 100%;
    
    }
  
    &[data-tiles="3"]{
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 100%;

    }
  
    &[data-tiles="4"]{
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      
    }
  
    &[data-tiles="5"]{
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: repeat(2, 1fr);
      @include grid-items(( 1:4, 2:4, 3:4, 4:6, 5:6 ));

    }
  
    &[data-tiles="6"]{
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 50%);

    }
  
    &[data-tiles="7"]{
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: repeat(4, 33.3%);
      @include grid-items(( 1:4, 2:4, 3:4, 4:6, 5:6, 6:6, 7:6 ));

    }

    &[data-tiles="8"]{
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: repeat(3, 50%);
      @include grid-items(( 1:4, 2:4, 3:4, 4:6, 5:6, 6:4, 7:4, 8:4 ));

    }

    &[data-tiles="9"]{
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: repeat(4, 50%);
      @include grid-items(( 1:4, 2:4, 3:4, 4:4, 5:4, 6:4, 7:4, 8:4, 9:4 ));
    
    }
    
    &[data-tiles="10"]{
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: repeat(4, 50%);
      @include grid-items(( 1:4, 2:4, 3:4, 4:6, 5:6, 6:4, 7:4, 8:4, 9:6, 10:6 ));
    
    }

    &[data-tiles="11"]{
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: repeat(4, 50%);
      @include grid-items(( 1:4, 2:4, 3:4, 4:6, 5:6, 6:4, 7:4, 8:4, 9:4, 10:4, 11:4 ));
    
    }

    &[data-tiles="12"]{
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: repeat(4, 50%);
      @include grid-items(( 1:4, 2:4, 3:4, 4:4, 5:4, 6:4, 7:4, 8:4, 9:4, 10:4, 11:4, 12:4 ));
    
    }

    &[data-tiles="13"]{
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: repeat(5, 50%);
      @include grid-items(( 1:4, 2:4, 3:4, 4:4, 5:4, 6:4, 7:6, 8:6, 9:4, 10:4, 11:4, 12:6, 13:6 ));
    
    }

    &[data-tiles="14"]{
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: repeat(5, 50%);
      @include grid-items(( 1:4, 2:4, 3:4, 4:4, 5:4, 6:4, 7:6, 8:6, 9:4, 10:4, 11:4, 12:4, 13:4, 14:4 ));
    
    }
    
  
  }
  
  
  @media(orientation: portrait) and (max-width: 479px){
  
  
    &[data-tiles="1"]{
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, 100%);
    
    }
  
    &[data-tiles="2"]{
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, 50%);
    
    }
  
    &[data-tiles="3"]{
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, 50%);
    
    }
  
    &[data-tiles="4"]{
      grid-template-columns: 1fr;
      grid-template-rows: repeat(4, 50%);
    
    }
  
    &[data-tiles="5"]{
      grid-template-columns: 1fr;
      grid-template-rows: repeat(5, 33.3%);
    
    }
  
    &[data-tiles="6"]{
      grid-template-columns: 1fr;
      grid-template-rows: repeat(6, 33.3%);
    
    }
  
    &[data-tiles="7"]{
      grid-template-columns: 1fr;
      grid-template-rows: repeat(7, 33.3%);
    
    }

    &[data-tiles="8"]{
      grid-template-columns: 1fr;
      grid-template-rows: repeat(8, 33.3%);
    
    }

    &[data-tiles="9"]{
      grid-template-columns: 1fr;
      grid-template-rows: repeat(9, 33.3%);
    
    }

    &[data-tiles="10"]{
      grid-template-columns: 1fr;
      grid-template-rows: repeat(10, 33.3%);
    
    }

    &[data-tiles="11"]{
      grid-template-columns: 1fr;
      grid-template-rows: repeat(11, 33.3%);
    
    }

    &[data-tiles="12"]{
      grid-template-columns: 1fr;
      grid-template-rows: repeat(12, 33.3%);
    
    }

    &[data-tiles="13"]{
      grid-template-columns: 1fr;
      grid-template-rows: repeat(13, 33.3%);
    
    }

    &[data-tiles="14"]{
      grid-template-columns: 1fr;
      grid-template-rows: repeat(14, 33.3%);
    
    }
    
  }
  
  @media(orientation: portrait) and (min-width: 480px){
  
    &[data-tiles="1"]{
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, 100%);
    
    }
  
    &[data-tiles="2"]{
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, 1fr);
    
    }
  
    &[data-tiles="3"]{
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, 1fr);
    
    }
  
    &[data-tiles="4"]{
      grid-template-columns: repeat(2, 50vw);
      grid-template-rows: repeat(2, 1fr);
    
    }
  
    &[data-tiles="5"]{
      grid-template-columns: repeat(2, 50vw);
      grid-template-rows: repeat(3, 1fr);
      @include grid-items(( 1:1, 2:1, 3:2, 4:1, 5:1 ));
      
    }
  
    &[data-tiles="6"]{
      grid-template-columns: repeat(2, 50vw);
      grid-template-rows: repeat(3, 33.3%);
    
    }
  
    &[data-tiles="7"]{
      grid-template-columns: repeat(2, 50vw);
      grid-template-rows: repeat(4, 33.3%);
      @include grid-items(( 1:1, 2:1, 3:1, 4:1, 5:2, 6:1, 7:1 ));

    }
    
    &[data-tiles="8"]{
      grid-template-columns: repeat(2, 50vw);
      grid-template-rows: repeat(5, 33.3%);
      @include grid-items(( 1:1, 2:1, 3:1, 4:1, 5:2, 6:1, 7:1, 8:2 ));

    }

    &[data-tiles="9"]{
      grid-template-columns: repeat(2, 50vw);
      grid-template-rows: repeat(5, 33.3%);
      @include grid-items(( 1:1, 2:1, 3:1, 4:1, 5:2, 6:1, 7:1, 8:1, 9:1 ));

    }

    &[data-tiles="10"]{
      grid-template-columns: repeat(2, 50vw);
      grid-template-rows: repeat(6, 33.3%);
      @include grid-items(( 1:1, 2:1, 3:1, 4:1, 5:2, 6:1, 7:1, 8:1, 9:1, 10:2 ));

    }

    &[data-tiles="11"]{
      grid-template-columns: repeat(2, 50vw);
      grid-template-rows: repeat(6, 33.3%);
      @include grid-items(( 1:1, 2:1, 3:1, 4:1, 5:2, 6:1, 7:1, 8:1, 9:1, 10:1, 11:1 ));

    }

    &[data-tiles="12"]{
      grid-template-columns: repeat(2, 50vw);
      grid-template-rows: repeat(7, 33.3%);
      @include grid-items(( 1:1, 2:1, 3:1, 4:1, 5:2, 6:1, 7:1, 8:1, 9:1, 10:2, 11:1, 12:1 ));

    }

    &[data-tiles="13"]{
      grid-template-columns: repeat(2, 50vw);
      grid-template-rows: repeat(8, 33.3%);
      @include grid-items(( 1:1, 2:1, 3:1, 4:1, 5:2, 6:1, 7:1, 8:1, 9:1, 10:2, 11:1, 12:1, 13:2 ));

    }

    &[data-tiles="14"]{
      grid-template-columns: repeat(2, 50vw);
      grid-template-rows: repeat(8, 33.3%);
      @include grid-items(( 1:1, 2:1, 3:1, 4:1, 5:2, 6:1, 7:1, 8:1, 9:1, 10:2, 11:1, 12:1, 13:1, 14:1 ));

    }
  
  }
  
}

