@import '../variables.scss';

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0px;
  flex: 0 0 $header-height;
  z-index: 5;
  background-color: var(--header-background-color);

  head-left {
    flex: 1 1 40%;
    text-align: left;

    button:not(:first-of-type) {
      border-left: 1px solid var(--header-icon-divider-color);
    }
  }

  head-center {
    flex: 1 1 auto;

    button {
      text-align: center;
      background: none;
      border: none;
    }

    img {
      max-width: 100%;
      height: 45px;
    }

    h1 {
      color: var(--header-icon-color);
    }
  }

  head-right {
    flex: 1 1 40%;
    text-align: right;

    button:not(:last-of-type) {
      border-right: 1px solid var(--header-icon-divider-color);
    }
  }

  head-left,
  head-right {
    button {
      svg {
        display: inline-block;
        vertical-align: top;
        fill: var(--header-icon-color);
        width: 25px;
        height: 25px;
      }

      &.active svg {
        fill: var(--header-icon-active-color);
      }
    }
  }

  @media (min-width: $small) and (max-width: $xmedium) and (orientation: landscape) {
    @at-root .APP.MOBILEDEVICE & {
      display: none !important;
    }
  }
}
