@import '../variables.scss';

form {
  position: relative;
  &.drop-panel-form {
    width: 100%;
    max-width: 400px;
    padding: 0 10px;
    margin: 40px auto;
  }

  &.form-disabled:before {
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-color: var(--drop-panel-background-color);
    opacity: 0.5;
    z-index: 1;
  }

  label {
    display: block;
    text-transform: uppercase;
    padding-left: 5px;
    margin-top: 5px;
    text-align: left;
    color: var(--form-label-color);
  }

  input[type='text'],
  input[type='email'],
  input[type='number'],
  textarea,
  select {
    background-color: var(--input-background-color);
    width: calc(100% - 10px);
    margin: 5px;
    -webkit-appearance: none;
    border: none;
    padding: 10px;
    font-size: 100%;
    //@include rounded(true, true, true, true);
  }

  .select {
    position: relative;
    &:before {
      content: '▼';
      color: var(--select-input-arrow-color);
      font-size: 12px;
      position: absolute;
      right: 20px;
      top: 37px;
      pointer-events: none;
    }
  }

  textarea {
    height: 60px;
  }

  .checkbox {
    padding-top: 15px;

    label {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      > span {
        display: inline-block;
        flex: 0 0 60px;

        input {
          position: absolute;
          opacity: 0;
          width: 50px;
          height: 50px;

          &:checked + span {
            background-color: var(--checkbox-active-color);
            border: none;
            svg {
              fill: white;
            }
          }
        }

        > span {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          border: 2px solid var(--checkbox-color);

          // TODO: This may need to be cleaned up, depending on how the components looks at different rounded values.
          @include rounded(true, true, true, true);

          svg {
            fill: var(--checkbox-color);
          }
        }
      }

      > div {
        text-transform: initial;
        font-size: 75%;
        flex: 1 1 calc(100% - 60px);
        text-align: left;

        a {
          color: var(--checkbox-label-link-color);
        }
      }
    }

    > i {
      flex: 0 0 100%;
    }
  }

  button[type='submit'] {
    font-size: 110%;
    background-color: var(--form-submit-button-background-color);
    color: var(--form-submit-button-color);
    margin-top: 20px;
    width: calc(100% - 10px);

    &:disabled {
      opacity: 0.5;
    }
  }

  i {
    display: block;
    color: var(--form-field-validation-color);
    text-align: right;
    padding-right: 15px;
  }
}

.form-success {
  width: 100%;
  max-width: 400px;
  padding: 0 10px;
  margin: 40px auto;
}
