@import '../variables.scss';

// TODO: Move to own component
.side-panel,
.side-panel-right {
  width: 350px;
  position: fixed;
  height: calc(100% - #{$header-height});
  padding: 20px 20px 0px 40px;
  overflow: hidden;
  left: -400px;

  z-index: 3;
  text-align: left;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  background-color: var(--sidebar-background-color);
  color: var(--sidebar-color);

  .side-panel-title {
    color: var(--sidebar-title-color);
    padding-bottom: 5px;
    border-bottom: 1px solid var(--sidebar-divider-color);

    @at-root .APP.ELECTRON & {
      width: 100%;
      text-align: right;
      padding-right: 40px;
      br {
        display: none;
      }
    }
  }

  .side-panel-scroller {
    overflow: hidden;
    overflow-y: scroll;
    padding-bottom: 100px;
  }

  &:before {
    pointer-events: none;
    content: '';
    position: absolute;
    bottom: 0px;
    height: 100px;

    width: 100%;
    left: 0;
    z-index: 1;
    background: var(--sidebar-background-color);
    background: -webkit-linear-gradient(top, var(--sidebar-background-color-tint), var(--sidebar-background-color));
    background: -moz-linear-gradient(top, var(--sidebar-background-color-tint), var(--sidebar-background-color));
    background: linear-gradient(to bottom, var(--sidebar-background-color-tint), var(--sidebar-background-color));
  }

  .filter-group {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px 0;

    &:not(:first-of-type) {
      border-top: 1px solid var(--sidebar-divider-color);
    }

    h4 {
      margin-bottom: 10px;
    }

    p {
      color: var(--sidebar-color);
    }

    @at-root .APP:not(.ELECTRON) &.range {
      p,
      div {
        max-width: 245px;
        margin-left: 0;
      }
    }

    p {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      span.status-marker {
        margin-left: 8px;
      }
    }

    svg {
      fill: var(--sidebar-icon-color);
      height: 30px;
      width: 30px;
    }

    form {
      width: 100%;
    }

    input {
      width: 245px;
      padding: 8px 10px;
      margin: 3px 0;
      background-color: var(--sidebar-inputfield-background-color);
      border: none;
      font-size: 100%;
      border-radius: 0;
      @include rounded(true, true, true, true);

      &[type='search'] {
        -webkit-appearance: none;
      }
    }

    button {
      background-color: var(--sidebar-button-background-color);
      border: 1px solid var(--sidebar-button-border-color);
      color: var(--sidebar-button-color);
      padding: 7px 10px;
      text-transform: uppercase;
      margin: 8px 10px 8px 0;
      width: 245px;
      cursor: pointer;
      font-size: 100%;
      @include rounded(true, true, true, true);

      &.active {
        background-color: var(--sidebar-button-active-background-color);
        border: 1px solid var(--sidebar-button-active-border-color);
        color: var(--sidebar-button-active-color);
      }

      &:disabled {
        opacity: 0.4;
        pointer-events: none;
      }
    }

    button + span.spcr {
      display: inline-block;
      width: 8px;
      height: 36px;
    }

    &.list {
      border: none;
      padding-top: 0;

      button {
        border-radius: 0 !important;
      }
    }

    &.list div {
      background-color: var(--sidebar-list-background-color);
      padding-top: 5px;
      margin-bottom: 5px;

      @include rounded(true, true, true, true);
    }

    &.list button {
      border: none;
      background-color: transparent;
      border-radius: 0;
      margin: 5px 12px;
      padding: 0 0 8px 0;
      width: calc(100% - 24px);
      text-align: left;

      &:not(:last-of-type) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.5);
      }
    }

    &.range {
      margin-bottom: 25px;
    }
  }

  p.range-values {
    display: flex;
    justify-content: space-between !important;
    margin-bottom: 20px;
    width: 100%;
    color: var(--sidebar-color);
  }

  .range-slider {
    width: 100%;
    height: 2px;
    margin: auto;
  }

  .thumb {
    cursor: pointer;
    position: absolute;
    z-index: 100;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    margin-top: -13px;
    background: #ffffff;
  }

  .track {
    position: relative;
    height: 2px;
    background-color: #ddd;
  }

  .track.track-1 {
    background: var(--sidebar-button-border-color);
  }

  .horizontal-slider .track {
    top: 20px;
    height: 4px;
  }

  .horizontal-slider .thumb {
    top: 12px;
    width: 10px;
    outline: none;
    height: 10px;
    line-height: 38px;
  }
}

.side-panel-right {
  right: -400px;
  left: auto;
  padding: 20px 40px 0px 20px;
}

.side-panel-right#screen-selector {
  top: $header-height;
  z-index: 9;

  .filter-group {
    button {
      width: 100%;
    }
  }
}

/* ELECTRON ?? */

.APP.ELECTRON .side-panel,
.APP.ELECTRON .side-panel-right {
  width: 100vw;
  height: 400px;
  bottom: -400px;
  left: 0;
  top: auto;
  right: auto;
  align-items: flex-end;

  .filter-title {
    width: calc(100vw - 60px);
    padding-right: 42px;
    text-align: right;
  }

  .filter-group {
    width: calc(100vw - 60px);
    justify-content: flex-end;
    padding-right: 20px;

    &[data-filter='favourite'] {
      padding-right: 0;
    }

    // &[data-filter="plotStatus"]{
    //   padding-right: 20px;
    // }

    button {
      width: auto;
      min-width: 150px;
    }
  }

  .filter-group.range {
    padding-right: 42px;
    align-items: flex-end;

    .range-slider,
    .range-values {
      width: 100%;
      max-width: 500px;
      margin-left: initial;
      margin-right: initial;
    }
  }

  .filter-group:not(.range) {
    flex-direction: row;

    p {
      margin-left: 25px;

      span.status-marker {
        margin-left: 0px;
      }
    }
  }
}

.APP.ELECTRON .side-panel-right {
  z-index: 4;
  height: 250px;
}
