@import "../variables.scss";

.splash {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed; 
  background-color: var(--splash-background-color);
  width: 100vw; 
  height: 100%; 
  z-index: 10;

  .splash-background {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    pointer-events: none;
  }

  video, iframe {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  
  video {
    pointer-events: none;
  }

  .tour-embed {
    position: absolute;
    height: 100%;
    width: 100%;
    left: -50vw;
    margin: 0;
    object-fit: cover;
  }
  
  .video-embed {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    margin: 0;
    object-fit: cover;
  }

  h1, h3 {
    margin: 0 20px 30px 20px;
    position: relative;
    pointer-events: none;
    color: var(--splash-font-color);
    
    img {
      pointer-events: none;
      max-width: 100%;
      text-shadow: 0px 0px 25px var(--splash-font-shadow);
    }

  }
  
  h3 {
    font-size: 35px;
    text-shadow: 0px 0px 25px var(--splash-font-shadow);
  }

  > div {
    position: relative;
    margin: 0 20px 30px 20px;
    color: var(--splash-font-color);
  }

  .splash-trigger {
    cursor: pointer;
    position:fixed;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 10px; 
    right: calc(50% - 25px); 
    z-index: 88; 
    background-color: transparent;
    color: var(--splash-trigger-color);
    width: 50px;
    height: 50px;
  
    svg {
      width: 40px;
      height: 40px;
      fill: var(--splash-trigger-color);
    }

  }

}
