@import '../variables.scss';

.video-gallery {
  position: relative;
  height: 100%;
  width: 100%;

  .video-fallback {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  span.rotate {
    position: fixed;
    left: calc(50vw - 25px);
    bottom: 70px;
  }

  span.rotate svg {
    width: 50px;
    height: 50px;
    fill: var(--rotation-message-color);
  }

  .video-gallery-placeholder {
    position: relative;
    height: 100%;
    background-color: var(--video-gallery-placeholder-background-color);
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .video-zoom-button {
    position: absolute;
    right: 20px;
    bottom: 20px;
    width: 40px;
    height: 40px;
    background-color: var(--enlarge-zoom-button-background-color);
    padding: 10px;
    @include rounded(true, true, true, true);
    svg {
      fill: var(--enlarge-zoom-button-color);
      width: 20px;
      height: 20px;
    }

    &:hover {
      background-color: var(--enlarge-zoom-button-active-background-color);
    }
  }

  .icon-button:not(.video-zoom-button) {
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    width: 50px;
    height: 50px;
    padding: 10px;
    background-color: var(--enlarge-zoom-button-background-color);
    transition: 200ms;

    @include rounded(true, true, true, true);
    svg {
      fill: var(--enlarge-zoom-button-color);
      padding-left: 3px;
    }

    &:hover {
      background-color: var(--enlarge-zoom-button-active-background-color);
    }
  }

  @media (orientation: landscape) {
    span.rotate {
      display: none;
    }
  }
}
