@import '../variables.scss';

.video-embed {
  position: relative;
  height: 100%;

  .video-fallback {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .video-zoom-button {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 40px;
    height: 40px;
    background-color: var(--enlarge-zoom-button-background-color);
    padding: 10px;
    @include rounded(true, true, true, true);

    svg {
      fill: var(--enlarge-zoom-button-color);
      width: 20px;
      height: 20px;
    }

    &:hover {
      background-color: var(--enlarge-zoom-button-active-background-color);
    }
  }
}

.video-embed-placeholder {
  position: relative;
  height: 100%;
  background-color: var(--video-gallery-placeholder-background-color);
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  button {
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    width: 50px;
    height: 50px;
    padding: 10px;
    background-color: var(--enlarge-zoom-button-background-color);
    transition: 200ms;

    @include rounded(true, true, true, true);
    svg {
      fill: var(--enlarge-zoom-button-color);
    }

    &:hover {
      background-color: var(--enlarge-zoom-button-active-background-color);
    }
  }
}
