/* Units */
$header-height: 70px;
$header-withtitle-height: 135px;

/* Gaps */
$_gap: 5;
$gap: $_gap * 1px;
$gap2: $_gap * 2px;
$gap3: $_gap * 3px;
$gap4: $_gap * 4px;
$gap5: $_gap * 5px;
$gap6: $_gap * 6px;
$gap7: $_gap * 7px;
$gap8: $_gap * 8px;
$gap9: $_gap * 9px;
$gap10: $_gap * 10px;

/* Breakpoints */
$min: 320px;
$small: 480px;
$medium: 768px;
$xmedium: 992px;
$large: 1024px;
$xlarge: 1200px;
$max: 1600px;
$xmax: 2000px;

/* Rounded Mixin - this could be cleaner */
@mixin rounded($topLeft, $topRight, $bottomRight, $bottomLeft) {
  @at-root .APP[data-rounded='0.1'] & {
    @if $topLeft {
      border-top-left-radius: 2px;
    } @else {
      border-top-left-radius: 0px;
    }
    @if $topRight {
      border-top-right-radius: 2px;
    } @else {
      border-top-right-radius: 0px;
    }
    @if $bottomRight {
      border-bottom-right-radius: 2px;
    } @else {
      border-bottom-right-radius: 0px;
    }
    @if $bottomLeft {
      border-bottom-left-radius: 2px;
    } @else {
      border-bottom-left-radius: 0px;
    }
  }

  @at-root .APP[data-rounded='0.2'] & {
    @if $topLeft {
      border-top-left-radius: 4px;
    } @else {
      border-top-left-radius: 0px;
    }
    @if $topRight {
      border-top-right-radius: 4px;
    } @else {
      border-top-right-radius: 0px;
    }
    @if $bottomRight {
      border-bottom-right-radius: 4px;
    } @else {
      border-bottom-right-radius: 0px;
    }
    @if $bottomLeft {
      border-bottom-left-radius: 4px;
    } @else {
      border-bottom-left-radius: 0px;
    }
  }

  @at-root .APP[data-rounded='0.3'] & {
    @if $topLeft {
      border-top-left-radius: 6px;
    } @else {
      border-top-left-radius: 0px;
    }
    @if $topRight {
      border-top-right-radius: 6px;
    } @else {
      border-top-right-radius: 0px;
    }
    @if $bottomRight {
      border-bottom-right-radius: 6px;
    } @else {
      border-bottom-right-radius: 0px;
    }
    @if $bottomLeft {
      border-bottom-left-radius: 6px;
    } @else {
      border-bottom-left-radius: 0px;
    }
  }

  @at-root .APP[data-rounded='0.4'] & {
    @if $topLeft {
      border-top-left-radius: 8px;
    } @else {
      border-top-left-radius: 0px;
    }
    @if $topRight {
      border-top-right-radius: 8px;
    } @else {
      border-top-right-radius: 0px;
    }
    @if $bottomRight {
      border-bottom-right-radius: 8px;
    } @else {
      border-bottom-right-radius: 0px;
    }
    @if $bottomLeft {
      border-bottom-left-radius: 8px;
    } @else {
      border-bottom-left-radius: 0px;
    }
  }

  @at-root .APP[data-rounded='0.5'] & {
    @if $topLeft {
      border-top-left-radius: 10px;
    } @else {
      border-top-left-radius: 0px;
    }
    @if $topRight {
      border-top-right-radius: 10px;
    } @else {
      border-top-right-radius: 0px;
    }
    @if $bottomRight {
      border-bottom-right-radius: 10px;
    } @else {
      border-bottom-right-radius: 0px;
    }
    @if $bottomLeft {
      border-bottom-left-radius: 10px;
    } @else {
      border-bottom-left-radius: 0px;
    }
  }

  @at-root .APP[data-rounded='0.6'] & {
    @if $topLeft {
      border-top-left-radius: 12px;
    } @else {
      border-top-left-radius: 0px;
    }
    @if $topRight {
      border-top-right-radius: 12px;
    } @else {
      border-top-right-radius: 0px;
    }
    @if $bottomRight {
      border-bottom-right-radius: 12px;
    } @else {
      border-bottom-right-radius: 0px;
    }
    @if $bottomLeft {
      border-bottom-left-radius: 12px;
    } @else {
      border-bottom-left-radius: 0px;
    }
  }

  @at-root .APP[data-rounded='0.7'] & {
    @if $topLeft {
      border-top-left-radius: 14px;
    } @else {
      border-top-left-radius: 0px;
    }
    @if $topRight {
      border-top-right-radius: 14px;
    } @else {
      border-top-right-radius: 0px;
    }
    @if $bottomRight {
      border-bottom-right-radius: 14px;
    } @else {
      border-bottom-right-radius: 0px;
    }
    @if $bottomLeft {
      border-bottom-left-radius: 14px;
    } @else {
      border-bottom-left-radius: 0px;
    }
  }

  @at-root .APP[data-rounded='0.8'] & {
    @if $topLeft {
      border-top-left-radius: 16px;
    } @else {
      border-top-left-radius: 0px;
    }
    @if $topRight {
      border-top-right-radius: 16px;
    } @else {
      border-top-right-radius: 0px;
    }
    @if $bottomRight {
      border-bottom-right-radius: 16px;
    } @else {
      border-bottom-right-radius: 0px;
    }
    @if $bottomLeft {
      border-bottom-left-radius: 16px;
    } @else {
      border-bottom-left-radius: 0px;
    }
  }

  @at-root .APP[data-rounded='0.9'] & {
    @if $topLeft {
      border-top-left-radius: 18px;
    } @else {
      border-top-left-radius: 0px;
    }
    @if $topRight {
      border-top-right-radius: 18px;
    } @else {
      border-top-right-radius: 0px;
    }
    @if $bottomRight {
      border-bottom-right-radius: 18px;
    } @else {
      border-bottom-right-radius: 0px;
    }
    @if $bottomLeft {
      border-bottom-left-radius: 18px;
    } @else {
      border-bottom-left-radius: 0px;
    }
  }

  @at-root .APP[data-rounded='1'] & {
    @if $topLeft {
      border-top-left-radius: 20px;
    } @else {
      border-top-left-radius: 0px;
    }
    @if $topRight {
      border-top-right-radius: 20px;
    } @else {
      border-top-right-radius: 0px;
    }
    @if $bottomRight {
      border-bottom-right-radius: 20px;
    } @else {
      border-bottom-right-radius: 0px;
    }
    @if $bottomLeft {
      border-bottom-left-radius: 20px;
    } @else {
      border-bottom-left-radius: 0px;
    }
  }
}

@mixin roundedElectron($topLeft, $topRight, $bottomRight, $bottomLeft) {
  @at-root .APP.ELECTRON[data-rounded='0.1'] & {
    @if $topLeft {
      border-top-left-radius: 2px;
    } @else {
      border-top-left-radius: 0px;
    }
    @if $topRight {
      border-top-right-radius: 2px;
    } @else {
      border-top-right-radius: 0px;
    }
    @if $bottomRight {
      border-bottom-right-radius: 2px;
    } @else {
      border-bottom-right-radius: 0px;
    }
    @if $bottomLeft {
      border-bottom-left-radius: 2px;
    } @else {
      border-bottom-left-radius: 0px;
    }
  }

  @at-root .APP.ELECTRON[data-rounded='0.2'] & {
    @if $topLeft {
      border-top-left-radius: 4px;
    } @else {
      border-top-left-radius: 0px;
    }
    @if $topRight {
      border-top-right-radius: 4px;
    } @else {
      border-top-right-radius: 0px;
    }
    @if $bottomRight {
      border-bottom-right-radius: 4px;
    } @else {
      border-bottom-right-radius: 0px;
    }
    @if $bottomLeft {
      border-bottom-left-radius: 4px;
    } @else {
      border-bottom-left-radius: 0px;
    }
  }

  @at-root .APP.ELECTRON[data-rounded='0.3'] & {
    @if $topLeft {
      border-top-left-radius: 6px;
    } @else {
      border-top-left-radius: 0px;
    }
    @if $topRight {
      border-top-right-radius: 6px;
    } @else {
      border-top-right-radius: 0px;
    }
    @if $bottomRight {
      border-bottom-right-radius: 6px;
    } @else {
      border-bottom-right-radius: 0px;
    }
    @if $bottomLeft {
      border-bottom-left-radius: 6px;
    } @else {
      border-bottom-left-radius: 0px;
    }
  }

  @at-root .APP.ELECTRON[data-rounded='0.4'] & {
    @if $topLeft {
      border-top-left-radius: 8px;
    } @else {
      border-top-left-radius: 0px;
    }
    @if $topRight {
      border-top-right-radius: 8px;
    } @else {
      border-top-right-radius: 0px;
    }
    @if $bottomRight {
      border-bottom-right-radius: 8px;
    } @else {
      border-bottom-right-radius: 0px;
    }
    @if $bottomLeft {
      border-bottom-left-radius: 8px;
    } @else {
      border-bottom-left-radius: 0px;
    }
  }

  @at-root .APP.ELECTRON[data-rounded='0.5'] & {
    @if $topLeft {
      border-top-left-radius: 10px;
    } @else {
      border-top-left-radius: 0px;
    }
    @if $topRight {
      border-top-right-radius: 10px;
    } @else {
      border-top-right-radius: 0px;
    }
    @if $bottomRight {
      border-bottom-right-radius: 10px;
    } @else {
      border-bottom-right-radius: 0px;
    }
    @if $bottomLeft {
      border-bottom-left-radius: 10px;
    } @else {
      border-bottom-left-radius: 0px;
    }
  }

  @at-root .APP.ELECTRON[data-rounded='0.6'] & {
    @if $topLeft {
      border-top-left-radius: 12px;
    } @else {
      border-top-left-radius: 0px;
    }
    @if $topRight {
      border-top-right-radius: 12px;
    } @else {
      border-top-right-radius: 0px;
    }
    @if $bottomRight {
      border-bottom-right-radius: 12px;
    } @else {
      border-bottom-right-radius: 0px;
    }
    @if $bottomLeft {
      border-bottom-left-radius: 12px;
    } @else {
      border-bottom-left-radius: 0px;
    }
  }

  @at-root .APP.ELECTRON[data-rounded='0.7'] & {
    @if $topLeft {
      border-top-left-radius: 14px;
    } @else {
      border-top-left-radius: 0px;
    }
    @if $topRight {
      border-top-right-radius: 14px;
    } @else {
      border-top-right-radius: 0px;
    }
    @if $bottomRight {
      border-bottom-right-radius: 14px;
    } @else {
      border-bottom-right-radius: 0px;
    }
    @if $bottomLeft {
      border-bottom-left-radius: 14px;
    } @else {
      border-bottom-left-radius: 0px;
    }
  }

  @at-root .APP.ELECTRON[data-rounded='0.8'] & {
    @if $topLeft {
      border-top-left-radius: 16px;
    } @else {
      border-top-left-radius: 0px;
    }
    @if $topRight {
      border-top-right-radius: 16px;
    } @else {
      border-top-right-radius: 0px;
    }
    @if $bottomRight {
      border-bottom-right-radius: 16px;
    } @else {
      border-bottom-right-radius: 0px;
    }
    @if $bottomLeft {
      border-bottom-left-radius: 16px;
    } @else {
      border-bottom-left-radius: 0px;
    }
  }

  @at-root .APP.ELECTRON[data-rounded='0.9'] & {
    @if $topLeft {
      border-top-left-radius: 18px;
    } @else {
      border-top-left-radius: 0px;
    }
    @if $topRight {
      border-top-right-radius: 18px;
    } @else {
      border-top-right-radius: 0px;
    }
    @if $bottomRight {
      border-bottom-right-radius: 18px;
    } @else {
      border-bottom-right-radius: 0px;
    }
    @if $bottomLeft {
      border-bottom-left-radius: 18px;
    } @else {
      border-bottom-left-radius: 0px;
    }
  }

  @at-root .APP.ELECTRON[data-rounded='1'] & {
    @if $topLeft {
      border-top-left-radius: 20px;
    } @else {
      border-top-left-radius: 0px;
    }
    @if $topRight {
      border-top-right-radius: 20px;
    } @else {
      border-top-right-radius: 0px;
    }
    @if $bottomRight {
      border-bottom-right-radius: 20px;
    } @else {
      border-bottom-right-radius: 0px;
    }
    @if $bottomLeft {
      border-bottom-left-radius: 20px;
    } @else {
      border-bottom-left-radius: 0px;
    }
  }
}

/* Rounded Mixin - this could be cleaner */
@mixin roundedIconButton($topLeft, $topRight, $bottomRight, $bottomLeft) {
  @at-root .APP[data-rounded='0.1'] & {
    @if $topLeft {
      border-top-left-radius: 3px;
    }
    @if $topRight {
      border-top-right-radius: 3px;
    }
    @if $bottomRight {
      border-bottom-right-radius: 3px;
    }
    @if $bottomLeft {
      border-bottom-left-radius: 3px;
    }
  }

  @at-root .APP[data-rounded='0.2'] & {
    @if $topLeft {
      border-top-left-radius: 5px;
    }
    @if $topRight {
      border-top-right-radius: 5px;
    }
    @if $bottomRight {
      border-bottom-right-radius: 5px;
    }
    @if $bottomLeft {
      border-bottom-left-radius: 5px;
    }
  }

  @at-root .APP[data-rounded='0.3'] & {
    @if $topLeft {
      border-top-left-radius: 7px;
    }
    @if $topRight {
      border-top-right-radius: 7px;
    }
    @if $bottomRight {
      border-bottom-right-radius: 7px;
    }
    @if $bottomLeft {
      border-bottom-left-radius: 7px;
    }
  }

  @at-root .APP[data-rounded='0.4'] & {
    @if $topLeft {
      border-top-left-radius: 10px;
    }
    @if $topRight {
      border-top-right-radius: 10px;
    }
    @if $bottomRight {
      border-bottom-right-radius: 10px;
    }
    @if $bottomLeft {
      border-bottom-left-radius: 10px;
    }
  }

  @at-root .APP[data-rounded='0.5'] & {
    @if $topLeft {
      border-top-left-radius: 12px;
    }
    @if $topRight {
      border-top-right-radius: 12px;
    }
    @if $bottomRight {
      border-bottom-right-radius: 12px;
    }
    @if $bottomLeft {
      border-bottom-left-radius: 12px;
    }
  }

  @at-root .APP[data-rounded='0.6'] & {
    @if $topLeft {
      border-top-left-radius: 14px;
    }
    @if $topRight {
      border-top-right-radius: 14px;
    }
    @if $bottomRight {
      border-bottom-right-radius: 14px;
    }
    @if $bottomLeft {
      border-bottom-left-radius: 14px;
    }
  }

  @at-root .APP[data-rounded='0.7'] & {
    @if $topLeft {
      border-top-left-radius: 17px;
    }
    @if $topRight {
      border-top-right-radius: 17px;
    }
    @if $bottomRight {
      border-bottom-right-radius: 17px;
    }
    @if $bottomLeft {
      border-bottom-left-radius: 17px;
    }
  }

  @at-root .APP[data-rounded='0.8'] & {
    @if $topLeft {
      border-top-left-radius: 20px;
    }
    @if $topRight {
      border-top-right-radius: 20px;
    }
    @if $bottomRight {
      border-bottom-right-radius: 20px;
    }
    @if $bottomLeft {
      border-bottom-left-radius: 20px;
    }
  }

  @at-root .APP[data-rounded='0.9'] & {
    @if $topLeft {
      border-top-left-radius: 22px;
    }
    @if $topRight {
      border-top-right-radius: 22px;
    }
    @if $bottomRight {
      border-bottom-right-radius: 22px;
    }
    @if $bottomLeft {
      border-bottom-left-radius: 22px;
    }
  }

  @at-root .APP[data-rounded='1'] & {
    @if $topLeft {
      border-top-left-radius: 25px;
    }
    @if $topRight {
      border-top-right-radius: 25px;
    }
    @if $bottomRight {
      border-bottom-right-radius: 25px;
    }
    @if $bottomLeft {
      border-bottom-left-radius: 25px;
    }
  }
}
