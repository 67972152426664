@import '../variables.scss';

.cookie-consent-bar {
  position: fixed;
  bottom: 0px;
  width: 100vw;
  background-color: var(--consent-background-color);
  z-index: 1;
  padding: 15px;
  text-align: left;

  .cookie-buttons {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 10px;
  }

  .terms-links {
    display: flex;
    gap: 15px;

    button {
      background: none;
      border: none;
      color: var(--theme-primary);
    }
  }

  .button-container {
    display: flex;
    gap: 5px;

    button {
      border: none;
      padding: 0 10px;
      height: 36px;
      line-height: 36px;
      font-size: 100%;
      cursor: pointer;
      transition: 300ms;
      pointer-events: all;
      @include rounded(true, true, true, true);

      &:hover {
        border: none;
      }

      &.accept {
        color: var(--consent-accept-button-color);
        background-color: var(--consent-accept-button-background-color);
      }

      &.manage {
        color: var(--consent-manage-button-color);
        background-color: var(--consent-manage-button-background-color);
      }

      &.decline {
        color: var(--consent-decline-button-color);
        background-color: var(--consent-decline-button-background-color);
      }

      &.essential {
        color: var(--consent-essential-button-color);
        background-color: var(--consent-essential-button-background-color);
      }
    }
  }
}
