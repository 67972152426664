@import '../variables.scss';

.loading-screen {
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #333;
  z-index: 9999;

  .spinner {
    position: relative;
    width: 5rem;
    height: 5rem;

    span {
      display: block;
      width: 5rem;
      height: 5rem;
      border: 4px solid rgba(255, 255, 255, 0.1);
      border-top: 4px solid rgba(255, 255, 255, 0.7);
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  h3 {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 21px;
    color: rgba(255, 255, 255, 0.8);
    margin: 20px 0 2px 0;
  }

  h4 {
    font-family: Arial, Helvetica, sans-serif;
    color: rgba(255, 255, 255, 0.4);
    font-weight: 100;
    font-size: 17px;
  }

  button {
    padding: 5px 10px;
    margin: 10px;
    border-radius: 3px;
  }
}
