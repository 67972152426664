@import './variables.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;

  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-weight: normal;

  ::-webkit-scrollbar {
    display: none;
  }
}

html {
  height: 100vh;
  height: -webkit-fill-available;
  -webkit-text-size-adjust: none;
}

body {
  position: fixed;
  overflow: hidden;
  margin: 0;
  font-size: 90%; // Mobile
  width: 100vw;
  height: 100vh;
  height: -webkit-fill-available;

  @media (min-width: 970px) {
    & {
      font-size: 100%;
    } // Tablet and above
  }
}

#root {
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100vw;
  height: 100%;
}

.debug {
  position: fixed;
  padding: 20px;
  text-align: left;
  z-index: 99999;
  width: 30vw;
  height: 40vh;
  left: 1vw;
  bottom: 1vh;
  background-color: rgba(255, 255, 255, 0.9);
  border: 2px solid tomato;
  pointer-events: scroll;
  overflow: hidden;
  overflow-y: scroll;
  color: #000000;
}

// GENERAL STYLES - -

img {
  display: block;
}

p {
  font-size: var(--paragraph-fontsize);
  color: var(--paragraph-color);
}

h1 {
  font-size: var(--h1-fontsize);
  span {
    font-family: inherit;
  }
}
h2 {
  font-size: var(--h2-fontsize);
}
h3 {
  font-size: var(--h3-fontsize);
}
h4 {
  font-size: var(--h4-fontsize);
}
h5 {
  font-size: var(--h5-fontsize);
}
