@import '../variables.scss';

.button {
  font-weight: 700;
  border: 0;

  cursor: pointer;
  display: inline-block;
  line-height: 1;
  transition: 200ms;

  @include rounded(true, true, true, true);

  &--regular {
    font-size: 14px;
    padding: $gap2 $gap4;
  }

  &--large {
    font-size: 16px;
    padding: $gap3 $gap5;
  }
}

.button-group {
  display: flex;

  button {
    border: 1px solid var(--button-border-color);
    background-color: var(--button-background-color);
    color: var(--button-color);
    margin: 0;
    transition: 300ms;

    svg path {
      fill: var(--button-color);
      transition: 300ms;
    }

    &.active {
      background-color: var(--button-active-background-color);
      border: 1px solid var(--button-active-border-color);
      color: var(--button-active-color);

      svg path {
        fill: var(--button-active-color);
      }
    }

    @media (hover: hover) {
      &:hover {
        background-color: var(--button-active-background-color);
        border: 1px solid var(--button-active-border-color);
        color: var(--button-active-color);

        svg path {
          fill: var(--button-active-color);
        }
      }
    }

    &:first-of-type {
      @include rounded(true, false, false, true);
    }

    &:last-of-type {
      @include rounded(false, true, true, false);
    }
  }
}
