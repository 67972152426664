@import '../../variables.scss';

.contact-scene {
  background-color: var(--contact-background-color);
}

.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  padding-top: $header-height;
  padding-bottom: 20px;

  h1 {
    font-size: 40px;
  }

  h4 {
    text-transform: uppercase;
  }

  p {
    line-height: 150%;
    font-size: 120%;
    color: var(--contact-paragraph-color);

    a {
      color: var(--contact-link-color);
      transition: color 200ms;

      @media (hover: hover) {
        &:hover {
          color: var(--contact-link-hover-color);
        }
      }
    }

    &.social-links {
      a {
        margin: 0 12px;

        svg {
          width: 30px;
          height: 30px;
          fill: var(--contact-social-link-color);
          transition: fill 200ms;
        }

        @media (hover: hover) {
          &:hover svg {
            fill: var(--contact-social-link-hover-color);
          }
        }
      }
    }

    &.company {
      color: var(--contact-paragraph-color);
      font-size: 140%;
      text-transform: uppercase;
      margin-bottom: 0;
    }
  }

  a.disclaimer {
    margin-top: auto;
    padding-top: 20px;
    color: var(--grey);
    text-decoration: none;
    font-size: 80%;
  }
}

// Shared with share.js and register-interest.js
.footer-logo {
  display: inline;
  margin-top: 5vh;
  max-height: 110px;
  width: 370px;
}
@media (max-width: 768px) {
  .footer-logo {
    width: 335px;
  }
}
