@import '../variables.scss';

.slide-gallery {
  background-color: var(--image-gallery-background-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.scene.slide-gallery {
  height: calc(100% - #{$header-height});
}

.swiper-container {
  position: relative;
  z-index: 0;
  height: 100%;

  .swiper-slide {
    max-height: 100%;
    max-width: 100%;

    &.gradient {
      &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 25%;
        bottom: 0;
        left: 0;
        z-index: 2;

        background: -webkit-linear-gradient(top, var(--image-gallery-gradient-color-top), var(--image-gallery-gradient-color-bottom));
        background: -moz-linear-gradient(top, var(--image-gallery-gradient-color-top), var(--image-gallery-gradient-color-bottom));
        background: linear-gradient(to bottom, var(--image-gallery-gradient-color-top), var(--image-gallery-gradient-color-bottom));
      }
    }
  }

  .gallery-zoom-button {
    position: absolute;
    right: 20px;
    bottom: 20px;
    width: 40px;
    height: 40px;
    background-color: var(--enlarge-zoom-button-background-color);
    padding: 10px;
    z-index: 89;
    transition: 200ms;
    @include rounded(true, true, true, true);

    svg {
      fill: var(--enlarge-zoom-button-color);
      width: 20px;
      height: 20px;
    }

    &:hover {
      background-color: var(--enlarge-zoom-button-active-background-color);
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }

  .swiper-pagination {
    pointer-events: none;
    position: fixed;
    bottom: 58px;
    left: auto;
    right: auto;
    color: var(--image-gallery-pagination-color);
  }

  .gallery-extras {
    position: fixed;
    z-index: 88;
    bottom: 20px;
    width: 100vw;
    left: 0;

    p {
      display: block;
      height: 20px;
      margin-top: 10px;
      color: var(--image-gallery-disclaimer-color);
      font-size: var(--image-gallery-disclaimer-fontsize);
    }

    .gallery-nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 150px;
      margin: 0 auto;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;

        &.swiper-button-disabled {
          opacity: 0.3;
        }

        svg {
          width: 30px;
          height: 30px;
          fill: var(--image-gallery-pagination-arrow-color);
        }
      }
    }
  }

  .rotate {
    position: fixed;
    left: calc(50vw - 25px);
    top: 125px;
    z-index: 1;

    svg {
      width: 50px;
      height: 50px;
      fill: var(--image-gallery-rotate-icon-color);
    }
  }

  @media (orientation: portrait) {
    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .swiper-slide img {
      width: 100vw;
    }
  }

  @media (orientation: landscape) {
    .swiper-slide img {
      height: 100%;
      width: 100vw;
      object-fit: cover;
    }

    .rotate {
      display: none;
    }
  }

  @media (max-width: $medium) and (orientation: landscape) {
    .swiper-slide {
      max-height: 100%;
    }

    .swiper-slide img {
      height: 100%;
      width: 100vw;
      object-fit: cover;
    }
  }
}

// Electron Version
.APP.ELECTRON .slide-gallery .gallery-extras {
  bottom: 120px;
}

.APP.ELECTRON .slide-gallery .swiper-pagination {
  bottom: 158px;
}
